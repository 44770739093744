import "./Homescreen/Login.css";
import React, { useState } from 'react';
//import { useNavigate, Link } from "react-router-dom";
import { Link } from "react-router-dom";
import NavbarLogin from "./NavbarLogin";

function Signin() {
    const [loginId, setLoginId] = useState('');
    const [password, setPassword] = useState('');
    //const navigate = useNavigate(); // Hook for navigation
    console.log('Signin');
    // Handle form submission
    const handleLogin = (e) => {
        e.preventDefault(); // Prevent default form submission

        // Save credentials to session storage
        sessionStorage.setItem('userId', loginId);
        sessionStorage.setItem('password', password); // You might not need to store the password depending on your use case

        // Redirect to the main page (or wherever client.js is used)
        //navigate('http://localhost:8000/action.html'); // Adjust the route to your actual application route
        //window.location.href = "http://localhost:8000/action.html";
        window.location.href = "https://148.66.156.20:8000/action.html";
    };

    return (
        <>
            <NavbarLogin />
            <div className="form_wrapper">
                <div className="form_container">
                    <div className="title_container">
                        <h2>Login Form</h2>
                    </div>
                    <form onSubmit={handleLogin} className="row clearfix">
                        <div className="input_field">
                            <span><i aria-hidden="true" className="fa fa-user"></i></span>
                            <input
                                type="text"
                                name="loginid"
                                placeholder="Login ID"
                                id="loginid"
                                required
                                value={loginId}
                                onChange={(e) => setLoginId(e.target.value)}
                            />
                        </div>

                        <div className="input_field">
                            <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                            <input
                                type="password"
                                name="Password"
                                placeholder="Password"
                                id="Password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="input_field">
                            <input
                                className="button"
                                type="submit"
                                value="Login"
                            />
                            <h2 align="center" className="or"> OR </h2>
                            <p>
                                Don't have an account? <Link to="/Login"> Please Register </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Signin;
