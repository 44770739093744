import "./Homescreen/Login.css";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useRef } from 'react';
import Select from "react-select"
import NavbarLogin from "./NavbarLogin";
import emailjs from '@emailjs/browser';

function Login() {

    const options = [  
    { label: "English", value: "English" },
    { label: "Tamil", value: "Tamil" },
    { label: "Hindi", value: "Hindi" },
    { label: "Maths", value: "Maths" },    
    { label: "Vedic Math", value: "Vedic Math" },
    { label: "Physics", value: "Physics" },
    { label: "Chemistry", value: "Chemistry" },
    { label: "Biology", value: "Biology" },
    { label: "Computer science", value: "Computer science" },    
    { label: "Career counselling for Students of grade 10 - 12", value: "Career counselling for Students of grade 10 - 12" },
    { label: "Others", value: "Others" }
  ];

  const country = [ 
    { label: "Select a country", value: "Select a country" },
    { label: "Australia", value: "Australia" },
    { label: "Canada", value: "Canada" },
    { label: "Denmark", value: "Denmark" },
    { label: "France", value: "France" },
    { label: "Finland", value: "Finland" },
    { label: "Germany", value: "Germany" },
    { label: "Greenland", value: "Greenland" },
    { label: "Hong Kong", value: "Hong Kong" },
    { label: "India", value: "India" },
    { label: "Italy", value: "Italy" },
    { label: "Japan", value: "Japan" },
    { label: "Malaysia", value: "Malaysia" }, 
    { label: "Mexico", value: "Mexico" },
    { label: "Newzealand", value: "Newzealand" },
    { label: "Norway", value: "Norway" },
    { label: "Poland", value: "Poland" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "Srilanka", value: "Srilanka" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "UAE", value: "UAE" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "United States", value: "United States" }
  ];

  const [selected, setSelected] = useState([]);
  const [cselected, csetSelected] = useState([]);
  
  // eslint-disable-next-line
  let [gender, setGender] = useState();

  const form = useRef();
    
  const SendEmail = (e) => {
      e.preventDefault();    
      emailjs.sendForm('service_cqwf1jr', 'template_9vp1hhd', form.current, 'RLVptYs1LmXuoikCr')
      .then((result) => {              
          alert('Successfully sent mail to AB4S Team!');
      }, (error) => {
          alert(JSON.stringify(error));
      });
    };
  
  return (
    <>
    <NavbarLogin/>
    <div class="form_wrapper">
    <div class="form_container">
    <form ref={form} onSubmit={SendEmail}>
    <div class="title_container">    
      <h2>Registration Form</h2>
    </div>
    <div class="row clearfix">
      <div class="">      
          <div class="row clearfix">
            <div class="col_half">
              <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                <input type="text" name="first_name" placeholder="First Name" id="first_name" required/>
              </div>
            </div>
            <div class="col_half">
              <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                <input type="text" name="last_name" placeholder="Last Name" id="last_name" required />
              </div>
            </div>
          </div>
          <div class="input_field"> <span><i aria-hidden="true" class="fa fa-envelope"></i></span>
            <input type="email" name="email" placeholder="Email" id="email" required />
          </div>
          <div class="input_field"> <span><i aria-hidden="true" class="fa fa-lock"></i></span>
            <input type="mobile" name="number" placeholder="Mobile Number with country code" id="number" required />
          </div>
          <div class="input_field"> <span><i aria-hidden="true" class="fa fa-lock"></i></span>
            <input type="whatsapp" name="whatsapp_number" placeholder="Whatsapp Number with country code" id="whatsapp_number" required />
          </div>  
          <div class="col_half">
            <label>Gender</label>
          </div>
          <div class="input_field">
              <div class="input_field radio_option">              
              <input type="radio" name="gender" value="Male" id="gender1" onChange={e=>setGender(e.target.value)} required/>
              <label for="gender1">Male</label>
              <input type="radio" name="gender" value="Female" id="gender2" onChange={e=>setGender(e.target.value)} required/>          
              <label for="gender2">Female</label>
              </div>
          </div>
          <div class="col_half">
            <label>User Type</label>
          </div>
          <div class="input_field">
              <div class="input_field radio_option">              
              <input type="radio" value="Tutor" name="usertype" id="rd1" required/>
              <label for="rd1">Student</label>
              <input type="radio" value="Student" name="usertype" id="rd2" />
              <label for="rd2">Tutor</label>
              </div>
          </div>
          <div class="input_field select_option">
              <Select
              id="subject"
              name="subject"
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy={"Select a subject"}             
              isMulti="true"
              required/>
          </div>        
          <div class="input_field select_option">
          <Select
              id="country"
              name="country"
              options={country}
              value={cselected}
              onChange={csetSelected}
              labelledBy={"Select a country"}
              required/>
          </div>
          <div class="input_field checkbox_option">
              <input type="checkbox" id="cb1" required/>
              <label for="cb1">I agree with terms and conditions</label>
          </div>
          <div class ="input_field">
           <input class="button" type="submit" value="Register" />                  
           <h2 align="center" class="or"> OR </h2>
           <p> Already have an account ? <Link to="/Signin"> Login </Link></p> 
        </div>
      </div>
    </div>
    </form>
        <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"></script>
        <script type="text/javascript">emailjs.init('RLVptYs1LmXuoikCr')</script>
  </div>
</div>
</>
  );
}

export default Login;