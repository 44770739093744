import "./Homescreen/NavbarLogin.css";
//import Home from "./Homescreen/index.jsx";
import { useNavigate } from "react-router-dom";

function NavbarLogin() {

const navigate=useNavigate();
return (
    <nav>
      <div className="nav">
        <img src="./img/AB4S_logo.png" alt="AB4S"/>
        <button type="submit" class="btn btn-outline-primary" onClick={() => {navigate("/")}}>Home</button>
      </div>
    </nav>
  );
}

export default NavbarLogin;
