/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import "./App.css";
import "./Home.css";

import React, {useEffect } from "react";
import { Route, Routes} from "react-router-dom";
import Home from "./Pages/Home/Homescreen";
import Login from "./Pages/Home/Login";
import AboutMe from "./Pages/Home/AboutMe";
import Signin from "./Pages/Home/Signin";
import io from 'socket.io-client';

// Replace with your server URL
const SOCKET_SERVER_URL = 'https://148.66.156.20:8000';
//const SOCKET_SERVER_URL = 'http://localhost:8000';
const App = () => {

  useEffect(() => {
    const socket = io(SOCKET_SERVER_URL);

    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('showChatMessage', (data) => {
      console.log('Received chat message:', data);
    });

    socket.on('showFileMessage', (data) => {
      console.log('Received file message:', data);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    // Cleanup on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="App">       
          <Routes>
            {/* <Route path="http://localhost:3000" element={<AboutMe />}></Route> */}
            <Route path="/" element={<Home />}></Route>
            <Route path="/Login" element={<Login />}></Route>
            <Route path="/AboutMe" element={<AboutMe />}></Route>
            <Route path="/Signin" element={<Signin />}></Route>   
            </Routes>
    </div>
  );
};

export default App;
